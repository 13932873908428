@import "../../App.css";

.container {
  padding: 60px 0 80px 0;
  margin-left: 10%;
  margin-right: 10%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 14px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}

.skillImageContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.skillImageContainer img {
  width: 50px;
}

@media screen and (max-width: 830px) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 34px;
  }

  .skills {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
}
