@import "../../App.css";

.aboutContainer {
  position: relative;
  background-color: rgba(12, 12, 12, 0.6);
  border-radius: 15px;
  padding: 4rem;
  margin-left: 10%;
  margin-right: 10%;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aboutImage {
  width: 28%;
  height: 28%;
  padding: 0 20px;
  align-self: left;
}

.aboutItems {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.aboutItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 25px;
}

.aboutItem img {
  width: 60px;
  margin-right: 30px;
}

@media (max-width: 830px) {
  .aboutContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 3rem;
  }

  .container {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  .content {
    flex-direction: column;
  }

  .aboutImage {
    display: none;
  }

  .aboutItems {
    margin-top: 29px;
    flex-direction: column;
  }

  .aboutItem {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .aboutItem img {
    align-self: center;
    padding-bottom: 20px;
  }
}
